import { ArrowBack, Close, ExpandMore, Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, IconButton, List, ListItem, ListItemText, MenuItem, Modal, Select, TextField, Tooltip, Typography } from '@mui/material'
import axios from 'axios'
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography'
import { CustomDescrypt } from 'global/func'
import { postDataWithResponse } from 'global/postFunc'
import { postSubmit } from 'global/postFunc'
import { serverUrl } from 'global/variable'
import React, { useEffect, useState } from 'react'
import userStore from 'store/userStore'

const Tambah = ({ text, korcam, relawan, vg, deleting, setDeleting, ...props }) => {
    const { user, addReq } = userStore(state => ({ user: state.user, addReq: state.addReq }));
    const decryptedUser = (user === null || user === undefined || user === '') ? "" : JSON.parse(CustomDescrypt(user));

    const [loading, setLoading] = useState(false)
    const [listDpt, setListDpts] = useState([])
    const [dpt, setDpt] = useState({})

    const [sumberData, setSumberData] = useState('')

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false)
        setDpt({})
    };

    const [desas, setDesas] = useState([]);
    const [kecs, setKecs] = useState([]);
    const [desa, setDesa] = useState('');
    const [kec, setKec] = useState('');

    useEffect(() => {
        if (open) {
            getKec()
            if (vg !== '') {
                setSumberData(vg)
                setKec(relawan.id_kec)
                // getDesa(relawan.id_kec)
                setDesa(relawan.id_kel)
            }
            else if (relawan !== '') {
                setSumberData(relawan)
                setKec(relawan.id_kec)
                // getDesa(relawan.id_kec)
                setDesa(relawan.id_kel)
            }
            else {
                setSumberData(korcam)
                setKec(korcam.id_kec)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    useEffect(() => {
        if (desa !== '') {
            reqDpt(desa)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [desa])

    useEffect(() => {
        if (kec !== '') {
            getDesa(kec)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kec])

    const getDesa = async (params) => {
        addReq()
        const data = await axios.get(serverUrl + '/desa/' + params);
        setDesas(data.data)
    }

    const reqDpt = (params) => {
        setLoading(true)
        postDataWithResponse(
            '/dpt',
            {
                status: '',
                id_kel: params
            },
            decryptedUser,
            (res) => {
                setLoading(false)
                setListDpts(res)
            },
            (error) => {
                setLoading(false)
                console.log(error)
            }
        )
    }


    const submit = (event) => {
        event.preventDefault();
        setLoading(true)
        addReq()
        postSubmit(
            '/entry/dtd/update',
            {
                _method: 'PUT',
                nik: dpt.nik,
                status: 'DTD',
                sumber_data_username: sumberData.nik,
                sumber_data_nama: sumberData.nama,
            },
            decryptedUser,
            afterSubmit,
            (error) => {
                setLoading(false)
                console.log(error);
            }
        )
    }

    const afterSubmit = () => {
        setDeleting(!deleting)
        setDpt({})
        handleClose()
    }

    const handleChangeDesa = (params) => {
        if (params === '') {
            setDesa('')
        }
        else {
            setDesa(params)
        }
    };

    const getKec = async () => {
        const kec = await axios.get(serverUrl + '/kecamatan');

        setKecs(kec.data)
    }

    const handleChangeKec = (params) => {
        if (params === '') {
            setKec('')
            setDesa('')
        }
        else {
            setKec(params)
            // getDesa(params)
        }
    };

    return (
        <>
            <Button onClick={handleOpen} {...props}>{text}</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    overflow: 'scroll',
                }}
            >
                <Grid container spacing={3} justifyContent='center' py={5}>
                    <Grid item xs={12} sm={9} md={9} lg={6}>
                        <Card >
                            <CardHeader
                                title={
                                    <SoftTypography variant='h6'>Kembali</SoftTypography>
                                }
                                avatar={
                                    <Tooltip title="Kembali">
                                        <IconButton variant="outlined" onClick={handleClose}><ArrowBack /></IconButton>
                                    </Tooltip>
                                }
                            />
                            {
                                loading ?
                                    (
                                        <Grid container spacing={3} py={5} justifyContent="center">
                                            <Grid item>
                                                <Box sx={{ display: 'flex' }}>
                                                    <CircularProgress />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )
                                    :
                                    (
                                        <CardContent component='form' onSubmit={submit}>
                                            <Grid container spacing={3} justifyContent="center">
                                                <Grid item xs={12} sm={12} md={10} lg={8}>
                                                    <SoftBox mb={1} ml={0.5}>
                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                            Pilih Kecamatan
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        defaultValue=''
                                                        value={kec || ''}
                                                        label="Kecamatan"
                                                        onChange={(event) => handleChangeKec(event.target.value)}
                                                        required
                                                    >
                                                        <MenuItem value=''><em>Pilih Desa</em></MenuItem>
                                                        {
                                                            kecs.map((kec) => (
                                                                <MenuItem key={kec.id_kec} value={kec.id_kec}>{kec.nama_kec}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3} justifyContent="center">
                                                <Grid item xs={12} sm={12} md={10} lg={8}>
                                                    <SoftBox mb={1} ml={0.5}>
                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                            Pilih Desa / Kelurahan
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        defaultValue=''
                                                        value={desa || ''}
                                                        label="Desa"
                                                        onChange={(event) => handleChangeDesa(event.target.value)}
                                                        required
                                                    >
                                                        <MenuItem value=''><em>Pilih Desa</em></MenuItem>
                                                        {
                                                            desas.map((des) => (
                                                                <MenuItem key={des.id_desa} value={des.id_desa}>{des.nama_desa}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3} justifyContent="center">
                                                <Grid item xs={12} sm={12} md={10} lg={8}>
                                                    <SoftBox mb={1} ml={0.5}>
                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                            Nama
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        options={listDpt}
                                                        fullWidth
                                                        getOptionLabel={(option) => option.nik + ' ' + option.nama}
                                                        onChange={(event, newValue) => {
                                                            newValue !== null && setDpt(newValue)
                                                        }}
                                                        onInputChange={(event, newInputValue) => {
                                                            newInputValue === "" && setDpt({})
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                            {
                                                dpt.nik !== undefined &&
                                                <Grid container spacing={3} pt={3} justifyContent="center">
                                                    <Grid item xs={12} sm={12} md={10} lg={8}>
                                                        <Accordion>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMore />}
                                                                aria-controls="panel1-content"
                                                                id="panel1-header"
                                                            >
                                                                <Typography variant='button'>
                                                                    Detail DPT
                                                                </Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <List sx={{ bgcolor: 'background.paper' }}>
                                                                    <ListItem>
                                                                        <ListItemText primary={
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                NIK
                                                                            </Typography>
                                                                        }
                                                                            secondary={
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    color="text.primary"
                                                                                >
                                                                                    {dpt.nik ? dpt.nik : '-'}
                                                                                </Typography>
                                                                            } />
                                                                    </ListItem>
                                                                    <Divider />
                                                                    <ListItem>
                                                                        <ListItemText primary={
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                No. KK
                                                                            </Typography>
                                                                        }
                                                                            secondary={
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    color="text.primary"
                                                                                >
                                                                                    {dpt.no_kk ? dpt.no_kk : '-'}
                                                                                </Typography>
                                                                            } />
                                                                    </ListItem>
                                                                    <Divider />
                                                                    <ListItem>
                                                                        <ListItemText primary={
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                Nama
                                                                            </Typography>
                                                                        }
                                                                            secondary={
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    color="text.primary"
                                                                                >
                                                                                    {dpt.nama ? dpt.nama : '-'}
                                                                                </Typography>
                                                                            } />
                                                                    </ListItem>
                                                                    <Divider />
                                                                    <ListItem>
                                                                        <ListItemText
                                                                            primary={
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    color="text.primary"
                                                                                >
                                                                                    Tempat/Tgl Lahir
                                                                                </Typography>
                                                                            }
                                                                            secondary={
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    color="text.primary"
                                                                                >
                                                                                    {dpt.tempat_lahir ? dpt.tempat_lahir + ', ' + dpt.tgl_lahir : '-'}
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </ListItem>
                                                                    <Divider />
                                                                    <ListItem>
                                                                        <ListItemText
                                                                            primary={
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    color="text.primary"
                                                                                >
                                                                                    Status Kawin
                                                                                </Typography>
                                                                            }
                                                                            secondary={
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    color="text.primary"
                                                                                >
                                                                                    {
                                                                                        dpt.stts_kawin
                                                                                    }
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </ListItem>
                                                                    <Divider />
                                                                    <ListItem>
                                                                        <ListItemText
                                                                            primary={
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    color="text.primary"
                                                                                >
                                                                                    Jenis Kelamin
                                                                                </Typography>
                                                                            }
                                                                            secondary={
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    color="text.primary"
                                                                                >
                                                                                    {dpt.jenkel === 'L' ? 'Laki-laki' : 'Perempuan'}
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </ListItem>
                                                                    <Divider />
                                                                    <ListItem>
                                                                        <ListItemText
                                                                            primary={
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    color="text.primary"
                                                                                >
                                                                                    Alamat
                                                                                </Typography>
                                                                            }
                                                                            secondary={
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    color="text.primary"
                                                                                >
                                                                                    {dpt.alamat ? `${dpt.alamat} RT. ${dpt.rt} RW. ${dpt.rw}, Kel. ${dpt.nama_kel}, Kec. ${dpt.nama_kec}, Kota. ${dpt.nama_kab}` : '-'}
                                                                                </Typography>
                                                                            } />
                                                                    </ListItem>
                                                                    <Divider />
                                                                    <ListItem>
                                                                        <ListItemText
                                                                            primary={
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    color="text.primary"
                                                                                >
                                                                                    RT / RW
                                                                                </Typography>
                                                                            }
                                                                            secondary={
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    color="text.primary"
                                                                                >
                                                                                    RT. {dpt.rt}, RW. {dpt.rw}
                                                                                </Typography>
                                                                            } />
                                                                    </ListItem>
                                                                    <Divider />
                                                                    <ListItem>
                                                                        <ListItemText primary={
                                                                            <Typography
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                TPS
                                                                            </Typography>
                                                                        }
                                                                            secondary={
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    color="text.primary"
                                                                                >
                                                                                    {dpt.nama_tps}
                                                                                </Typography>
                                                                            } />
                                                                    </ListItem>
                                                                    <Divider />
                                                                </List>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </Grid>
                                                </Grid>
                                            }
                                            <Grid container justifyContent='center' pt={3}>
                                                <Grid item xs={12} sm={12} md={10} lg={8}>
                                                    <Grid container justifyContent='flex-end'>
                                                        <Grid item>
                                                            <SoftBox mb={1} ml={0.5}>
                                                                <Button
                                                                    loadingPosition="start"
                                                                    startIcon={<Close />}
                                                                    variant="outlined"
                                                                    color='error'
                                                                    type='submit'
                                                                    onClick={handleClose}
                                                                >
                                                                    CLose
                                                                </Button>
                                                            </SoftBox>
                                                        </Grid>
                                                        <Grid item>
                                                            <SoftBox mb={1} ml={0.5}>
                                                                <LoadingButton
                                                                    loading={loading}
                                                                    loadingPosition="start"
                                                                    startIcon={<Save />}
                                                                    variant="contained"
                                                                    type='submit'
                                                                >
                                                                    Save
                                                                </LoadingButton>
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    )
                            }

                        </Card>
                    </Grid>
                </Grid>
            </Modal>
        </>
    )
}

export default Tambah