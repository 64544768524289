import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const MapComponent = ({ data = [], center = {} }) => {

    const containerStyle = {
        width: "100%",
        height: "400px",
    }

    const mapClicked = (event) => {
        console.log(event.latLng.lat(), event.latLng.lng())
    }

    return (
        <LoadScript googleMapsApiKey='AIzaSyBSPnDjyNgyZ-lCwoKg_gkzZaiRLbDc1Ic'>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={12}
                onClick={mapClicked}
                options={{
                    mapTypeControl: true,
                    streetViewControl: false,
                    draggable: true, // make map draggable
                    zoomControlOptions: { position: 9 },
                    keyboardShortcuts: false, // disable keyboard shortcuts
                    scaleControl: true, // allow scale controle
                    scrollwheel: true, // allow scroll wheel
                    //styles: mapsStyle,// change default map styles,
                }}
            >
                <Marker
                    key={0}
                    position={{ lat: parseFloat(data.lat), lng: parseFloat(data.longi) }}
                    label={'Data Center'}
                    draggable={false}
                />
            </GoogleMap>
        </LoadScript>
    );
};

export default MapComponent;