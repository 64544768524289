import { Box, CircularProgress, Typography, circularProgressClasses } from '@mui/material'

const PieProgres = ({ value, color }) => {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
                variant="determinate"
                sx={{
                    color: color,
                    opacity: 0.3
                }}
                size={75}
                thickness={6}
                value={100}
            />
            <CircularProgress
                variant="determinate"
                // disableShrink
                sx={{
                    color: color,
                    animationDuration: '550ms',
                    position: 'absolute',
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: 'round',
                    },
                }}
                size={80}
                thickness={6}
                value={value}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="h6" component="div" color={color} fontWeight='700'>
                    {`${value.toFixed(2)}%`}
                </Typography>
            </Box>
        </Box>
    )
}

export default PieProgres